import { combineReducers } from 'redux';

import { ajaxEpic } from './lib/epic';
import dux from './lib/dux';
import culdDux from './lib/culdDux';
import updateDux from './lib/updateDux';
import createDux from './lib/createDux';
import listDux from './lib/listDux';

const { reducer: $list, actions: list, epics: listEpics } = listDux({
  name: 'dndShipmentsList',
  url: '/api/demurrage-detention/v1/shipments/'
});
 
const { reducer: $summary, actions: summary, epics: summaryEpics } = listDux({
  name: 'dndSummary',
  url: '/api/demurrage-detention/v1/containers/summary'
});

const { reducer: $dndCostList, actions: dndCostList, epics: dndCostListEpics } = listDux({
  name: 'demurragDetentionCosts',
  url: '/api/demurrage-detention/v1/containers/demurrage-detention-costs'
});

const { reducer: $contianers, actions: contianers, epics: contianersEpics } = listDux({
  name: 'dndContainerList',
  url: '/api/demurrage-detention/v1/containers/'
});

const { reducer: $update, actions: update, epics: updateEpics } = updateDux({
  name: 'containerRateUpdate',
  url: '/api/demurrage-detention/v1/containers/'
});

const { reducer: $createRate, actions: createRate, epics: createRateEpics } = createDux({
  name: 'createRate',
  url: '/api/demurrage-detention/v1/rates/',
});

const { reducer: $updateRate, actions: updateRate, epics: updateRateEpics } = updateDux({
  name: 'rateUpdate',
  url:  '/api/demurrage-detention/v1/rates/',
});

const { reducer: $updateRates, actions: updateRates, epics: updateRatesEpics } = updateDux({
  name: 'containerRateCreateUpdate',
  url: '/api/demurrage-detention/v1/containers/rates'
});

const { reducer: $uploadBulkRatesFile, actions: uploadBulkRatesFile, epics: uploadBulkRatesFileEpics } = createDux({
  name: 'dndRates.upload',
  url: '/api/demurrage-detention/v1/rates/bulk',
  headers: { 'Content-Type': 'FormData'},
});

const { reducer: $dndRatesList, actions: dndRatesList, epics: dndRatesListEpics } = listDux({
  name: 'dndRatesList',
  url: '/api/demurrage-detention/v1/rates/'
});

export const actions = { list, contianers, update, createRate, updateRates, dndCostList, summary, uploadBulkRatesFile, dndRatesList, updateRate };
export const epics = [ ...listEpics, ...contianersEpics, ...updateEpics, ...createRateEpics, ...updateRatesEpics, ...dndCostListEpics, ...summaryEpics, ...uploadBulkRatesFileEpics, ...dndRatesListEpics, ...updateRateEpics];
export default combineReducers({ $list, $contianers, $update, $createRate, $updateRates, $dndCostList, $summary, $uploadBulkRatesFile, $dndRatesList, $updateRate });
